.main-footer{
    border-top: 2px black solid;
    color: black;
    background-color: white;
    padding-top: 1em;
    margin-top: 1rem;
    bottom: 0;
    position: absolute;
    left: 0;
    width: 84%;
}

.footer-row {
    display: flex;
    justify-content: space-between;
}


.footer-col a h2,
.footer-col a h1 {
    margin-top: 0;
    margin-bottom: 0;
}

.footer-col ul {
    margin-top: 0;
    list-style: none;
    padding: 0;
}

.footer-col a p {
    font-size: 0.9rem;
}

.post-info {
    font-size: 0.8rem;
    padding-bottom: 20px;
}

@media only screen and (max-width: 950px) {
    .footer-nav {
        display: none;
    }

    .footer-col a {
        font-size: 0.9rem;
    }

    .footer-col a p {
        font-size: 0.7rem;
    }

    .main-footer {
        width: 92%;
    }
}
