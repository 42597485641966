.accordion {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.accordion-item {
    margin-bottom: 16px;
    border: 2px solid black;
    border-radius: 30px;
}

.accordion-header {
    position: relative;
    border: none;
    width: 100%;
    height: auto;
    cursor: pointer;
    margin: 0;
    text-align: left;
    vertical-align: center;
    padding: 20px 60px 20px 30px;
}

.accordion-collapse {
    height: 0;
    overflow: hidden;
    transition: height 0.3s;
}

.accordion-collapse.open {
    height: auto;
}

.accordion-body {
    padding: 30px;
}