.profile-name {
    height: 10%;
}

.profile-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.5rem;
}

.profile-col {
    display: flex;
    flex-direction: column;
    border: black 2px solid;
    border-radius: 10px;
    padding: 1rem;
    width: 85vw;
    background: white;
    color: black;
}

.profile-col ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}


form {
    margin-top: auto;
    border-color: white;
}

input {
    margin-top: 0;
}

hr {
    margin: 1rem 0;
}

    @media only screen and (max-width: 800px) {
        .profile-row {
            flex-direction: column;
            text-align: center;
            align-items: center;
        }
    }