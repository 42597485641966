nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    background: white;
}

nav div {
    display: flex;
    justify-content: space-between;
}

a {
    text-decoration: none;
    color: black;
    transition: 0.2s ease-in-out;
}


#navbar {
    display: flex;
    align-items: center;
    
    margin: 0;
    padding: 0;
}

#navbar li {
    list-style: none;
    padding-left: 20px;
    position: relative;
}

#navbar li a {
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    color: black;

}

#navbar li a:hover,
a:hover {
    color: #416192;
}

#navbar li a:hover::after {
    content: "";
    width: 30%;
    height: 2px;
    background: #416192;
    position: absolute;
    bottom: -4px;
    left: 20px;
}

.nav-button {
    cursor: pointer;
    border: none;
    background: none;
    visibility: hidden;
    opacity: 0;
    margin: 0;
    display: none;
}

.li-hide {
    display: none;
}

.footer-nav {
    padding-left: 20rem;
}

@media only screen and (max-width: 950px) {
    .resp_nav .nav-open-button,
    .resp_nav .logo {
        visibility: hidden;
    }

    .li-hide {
        display: block;
    }

    .nav-button {
        visibility: visible;
        opacity: 1;
        display: block;
        font-size: 1.5rem;
    }

    #navbar {
        justify-content: center;
        position: fixed;
        flex-direction: column;
        top: 0;
        left: 0;
        height: 80%;
        width: 100%;
        gap: 1.5rem;
        transition: 1s;
        background-color: white;
        transform: translateY(-80vh);
    }

    .resp_nav {
        transform: none !important;
    }
}