.social {
    display: flex;
    flex-direction: row;

}

.social-icon  {
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
    transition: transform 0.5s;
}

@media only screen and (max-width: 1024px) {
    .social-icon {
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.3rem;
    }

}

@media only screen and (max-width: 400px) {
    .social-icon {
        width: 2rem;
        height: 2rem;
        padding: 0.3rem;
    }

}

.social-icon:hover {
    transform: scale(110%) translateY(-7px);
}
