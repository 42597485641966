@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --main-text-color: #557a95;
}

html, body{
  font-family: 'Inter', serif;
  height:100%;
  width:100%;
font-size: 1.1rem;
}

::-webkit-scrollbar { display: none; }

html {
  margin: 0;
  padding: 0;
}

.attention-text {
  font-weight: 200;
  font-size: 1.5rem;
  color: var(--main-text-color);
}

.header {
  font-size: 3rem;
}

header{
  top: 0;
  position: sticky;
  z-index: 10;
}

section {
  padding:  2rem 0;
  display: block;
  min-height: 50vh;
  height: auto;
}

nav,
.main-footer,
.inner-block {
  padding-right: 8vw;
  padding-left: 8vw;

}

#side-image {
  -webkit-user-select: none;
  width: 100%;

  height: auto;
  pointer-events: none;
transform: translateY(2rem);
}

button,
input {
  margin-top: 3rem;
  margin-bottom: 2rem;
  width: 100%;
  height: 3rem;
  border-radius: 30px;
  font-size: 1.1rem;
  background: white;
}


#introduction .inner-block span form input,
#about,
#profiles {
  background: white;
  transition: 0.25s;
}

.progressFill {
  background: var(--main-text-color);
}

#introduction .inner-block span form input:hover {
  background: white;
  transition: .25s;
}

#introduction .inner-block span form input {
  border-color: white;
}

#introduction,
#for-patients,
#contacts {
  background: white;
  color: black;
}

.positive-list {
  color: green;
  list-style-type: '✔ ';
}

.negative-list {
  color: red;
  list-style-type: '✘ ';
}

.positive-list p,
.negative-list p {
  color: black;
}

#education,
#reviews,
#faq {
  background: white;
}

.map-block {
  width: 400px;
  height: 400px;
}

.contact-block {
  display: flex;
  margin: 3rem 0;
  justify-content: space-around;
  gap: 2rem;
}

.contact-info {
  width: 500px;
}

.contact-block ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#introduction h2,
#introduction h3 {
  color: var(--main-text-color);
  font-weight: 200;
}

#introduction h2 {
  margin: 0;
  font-size: 4rem;
}

#introduction h3 {
  margin-top: 0;
  font-size: 3rem;
}

.question-block {
  padding: 0;
}

.question-block li {
  border: black 2px solid;
  margin-bottom: 15px;
  border-radius: 20px;
  padding-left: 30px;
  list-style: none;

}

h1 {
  margin-top: 0;
  font-size: 2rem;
}

p {
  text-align:left;
}

.year-list dt {
  color: var(--main-text-color);
font-size: 2rem;
  font-weight: 400;
}

.year-list dd {
  border-left: var(--main-text-color) 4px solid;
  margin: 0;
  padding-left: 30px;
}

.question-hide {
  opacity: 0;
  transition: .25s;
}

.question-show {
  opacity: 1;
  transition: .25s;
}

footer{
  position: relative;
margin-top: 15rem;
}

.intro-inner-block {
  align-items: flex-end;
  display: flex;
  gap:16vw;
}

.education-slider {
  display: flex;
  justify-content: center;
  max-width: 70%;
  height: 40vw;

  margin: 5rem auto 3rem;
}

.comment-slider {
  height: 20vw;
}

.map {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  width: 100%;
  height: 400px;
}


@media only screen and (max-width: 950px) {
  nav,
  .main-footer,
  .inner-block {
    padding-right: 4vw;
    padding-left: 4vw;
  }

  .header {
    font-size: 2rem;
  }

  .intro-inner-block {
    gap:8vw;
  }

  #introduction h2 {
    font-size: 2rem;
  }

  #introduction h3 {
    font-size: 1.5rem;
  }

  .contact-block {
    flex-direction: column;
  }

  .contact-info {
    width: 100%;
  }

  .map-block {

    justify-content: center;
  }

  .education-slider {
    max-width: 100%;
    /*height: 65vw;*/
  }
}



@media only screen and (max-width: 800px) {
  #side-image {
    transform: translate(-40%, 2rem);
  }

  .intro-inner-block {
    gap:0;
  }

  #introduction {
    display: block !important;

  }

  .intro-span {
    max-width: 65%;
    position: absolute;
    right: 0;
    text-shadow: -2px 2px 5px white;
  }
}

@media only screen and (max-width: 436px) {
  footer {
    margin-top: 15rem;
  }


}