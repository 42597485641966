.progressWrapper {
    background-color: white;
    height: 7px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
}

.progressFill {
    height: 7px;

    transition: 0.5s;
}